import React, { useState, useEffect } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import {
  SearchBar,
  EditTask,
  ConfirmationModal,
  NoData,
  NList,
  NonActiveTaskTile,
  FilterMenu,
} from "../../../components";
import { useLoadItems } from "../../../hooks/useLoadItems";
import { task } from "../../../api";
import { AxiosError } from "axios";
import { useMessageModal } from "../../../hooks/useMessage";
import { Task } from "../../../api/models/Task";
import { useAuth } from "../../../hooks/useAuth";
import notification from "../../../assets/images/notification.svg";
import { useTranslation } from "react-i18next";

export default function NotStartedTaskList() {
  const { showMessage } = useMessageModal();
  const { user } = useAuth();
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const [taskToDelete, setTaskToDelete] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [expendMenu, setExpendMenu] = useState(false);
  const [taskFilter, setTaskFilter] = useState("");
  const { t } = useTranslation();
  const { loading, items, hasNextPage, error, updateItem, deleteItem } =
    useLoadItems({
      loadItems: task.notActiveTask,
      page,
      search,
      taskFilter,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 100px 0px",
  });

  const doSearch = (keyword: string) => {
    if (keyword !== search) {
      setPage(1);
      setSearch(keyword);
    }
  };

  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  const deleteTask = (isOk: boolean) => {
    if (isOk) {
      task
        .deleteTask(taskToDelete)
        .then((res) => {
          deleteItem(taskToDelete);
          setTaskToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  const updateListItem = (updatedData: Task) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  function toggleMenuExpend() {
    setExpendMenu(!expendMenu);
  }

  const doFilter = (keyword: string) => {
    if (keyword !== taskFilter) {
      setPage(1);
      setTaskFilter(keyword);
    }
  };

  return (
    <>
      <Row>
        <section className="notification-bar shrink-width">
          <div> {t("goodMorning")}, {capitalizeFirst(user?.name)}! 👋</div>
          <div className="notification" onClick={toggleMenuExpend}>
            <img src={notification} alt="notification" />
            {user?.totalNotificationsCount &&
            user?.totalNotificationsCount > 0 ? (
              <span className="m-alert"></span>
            ) : null}
          </div>
          {expendMenu ? <NList /> : null}
        </section>
        <section className="main-container shrink-width" ref={rootRef}>
          <div className="heading-common">
            <h1>{t("Not started tasks")}</h1>
            <p>{t("manageProjectMsg")}</p>
          </div>
          <div className="member-list-wrapper">
            <SearchBar
              doSearch={doSearch}
              placeHolder={t("Search by title or description")}
            />
            <FilterMenu
              doFilter={doFilter}
              allTaskFilter={false}
              showSpan={true}
            />

            <div className="member-list-content scrollbar">
              {!items.length && !loading ? <NoData /> : null}
              {items.map((detail, key) => (
                <NonActiveTaskTile
                  key={detail._id + key}
                  task={detail}
                  editTask={() => setDetailsToShow(detail)}
                  deleteTask={() => setTaskToDelete(detail._id)}
                />
              ))}
              {/* <div className="bottom-graidient" ></div> */}
              {hasNextPage && (
                <div ref={sentryRef} className="spinner-wrap">
                  <div className="">
                    <Spinner />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Row>
      <EditTask
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("deleteTaskHeading")}
        body={<p>{t("deleteTaskDes")}</p>}
        show={!!taskToDelete}
        handleClose={() => setTaskToDelete("")}
        type="warning"
        callback={deleteTask}
        buttonMain="Remove"
        buttonSecondary="Cancel"
      />
    </>
  );
}

import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import {
  TeamMembersRow,
  ActiveTeamMemberTile,
  NonActiveTeamMemberTile,
} from "../../";
import { Link } from "react-router-dom";
import { team } from "../../../api";
import { Member } from "../../../api/models/User";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import Spinner from "react-bootstrap/Spinner";
import { USER_STATUS } from "../../../constants";
import { useTranslation } from "react-i18next";

function SeeAll() {
  return (
    <div className="active-list-grid align-items-center">
      <Link to={ROUTE_NAVIGATION_PATH.ACTIVE_MEMBER_LIST + '/' + USER_STATUS.INVITEE}>
        <Button variant="seeAll">See All users</Button>
      </Link>
    </div>
  );
}
export function NonActiveTeamMembers() {
  const [members, setMembers] = useState<Member[]>([]);
  const [loadingInvite, setLoadingInvite] = useState<boolean>(false);
  const { showMessage } = useMessageModal();
  const [hasNextPage, setHasNextPage] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    team
      .getAllTeamMembersByStatus({
        page: 1,
        count: 10,
        status: USER_STATUS.INVITEE,
      })
      .then((res: { data: Member[]; hasNextPage: boolean }) => {
        setMembers(res.data);
        setHasNextPage(res.hasNextPage);
      });
  }, []);

  const sendInviteToAll = () => {
    setLoadingInvite(true);
    team
      .sendInviteToAll()
      .then((res) => {
        showMessage({
          heading: "Success",
          body: <p>Invite sent to All successfully.</p>,
          type: "success",
        });
        setLoadingInvite(false);
      })
      .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
        showMessage({
          heading: "Error",
          body: <p>{err.response?.data?.errorMessage}</p>,
          type: "error",
        });
      });
  };
  return (
    <TeamMembersRow
      className="non-active-user-content-wrapper"
      heading={t("Pending Invitations")}
      leftButton={
        members.length > 0 ? (
          <Button
            variant="seeAll"
            onClick={sendInviteToAll}
            disabled={loadingInvite}
          >
            {loadingInvite ? <Spinner /> : null} {t("send an invitation to all")}
          </Button>
        ) : (
          <></>
        )
      }
    >
      {members.map((member) => (
        <NonActiveTeamMemberTile user={member} key={member._id} />
      ))}
      {hasNextPage ? <SeeAll /> : null}
      {members.length === 0 ? 
        <div className="dashboard-no-data">
          <p>{t("NoResultFound")}</p>
        </div> 
        :
        null
      }
    </TeamMembersRow>
  );
}

export function ActiveTeamMembers() {
  const [members, setMembers] = useState<Member[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    team
      .getAllTeamMembersByStatus({
        page: 1,
        count: 10,
        status: USER_STATUS.ACTIVE,
      })
      .then((res: { data: Member[] }) => {
        setMembers(res.data);
      });
  }, []);
  return (
    <TeamMembersRow
      heading={t("Active users")}
      leftButton={
        members.length > 0 ? (
          <Link to={ROUTE_NAVIGATION_PATH.ACTIVE_MEMBER_LIST + "/" + USER_STATUS.ACTIVE}>
            <Button variant="seeAll">{t("seeall")}</Button>
          </Link>
        ) : (
          <></>
        )
      }
    >
      {members.map((member) => (
        <ActiveTeamMemberTile user={member} key={member._id} />
      ))}
      {members.length === 0 ?
        <div className="dashboard-no-data">
          <p>{t("NoResultFound")}</p>
        </div> 
        :
        null
      }
    </TeamMembersRow>
  );
}

export default function MyProfileManageTeam() {
  const {t} = useTranslation();
  return (
    <>
      <div className="heading">
        <h2>{t("manageTeamheading")} {t("Team")}</h2>
        <Link to={ROUTE_NAVIGATION_PATH.MANAGE_TEAM_MEMBERS}>
          <Button variant="seeAll">{t("go to management")}</Button>
        </Link>
      </div>
      <ActiveTeamMembers />
      <NonActiveTeamMembers />
    </>
  );
}

import React, { ReactNode, useState } from "react";
import Row from "react-bootstrap/Row";
import iconDown from "../../../assets/images/arrow-down.svg";
import iconUp from "../../../assets/images/arrow-up.svg";
import { useLoadItems } from "../../../hooks/useLoadItems";
import useInfiniteScroll from "react-infinite-scroll-hook";
import Spinner from "react-bootstrap/Spinner";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { useMessageModal } from "../../../hooks/useMessage";
import { AxiosError } from "axios";
import { Member } from "../../../api/models/User";
import { team } from "../../../api";
import {
  SearchBar,
  EditTeamMember,
  ConfirmationModal,
  NoData,
} from "../../../components";
import { Link } from "react-router-dom";
import ManageTeamTile from "../../../components/TeamMember/ManageTeam/ManageTeamTile";
import { useTranslation } from "react-i18next";

type SortType = Record<string, 1 | -1>;

const TableHeading = ({
  text,
  keyName,
  sortBy,
  updateSortValue,
}: {
  text: string | React.ReactNode;
  keyName: string;
  sortBy?: SortType;
  updateSortValue: (value: SortType) => void;
}) => {
  const doSort = () => {
    if (sortBy?.[keyName]) {
      updateSortValue({ [keyName]: sortBy?.[keyName] * -1 } as SortType);
    } else {
      updateSortValue({ [keyName]: -1 });
    }
  };
  return (
    <>
      {text}
      <span className="arrow" onClick={doSort}>
        <img
          src={iconUp}
          alt="arrow"
          className={`toparrow ${
            sortBy?.[keyName] && sortBy?.[keyName] === -1 && "active"
          }`}
        />
        <img
          src={iconDown}
          alt="arrow"
          className={`downarrow ${
            sortBy?.[keyName] && sortBy?.[keyName] === 1 && "active"
          }`}
        />
      </span>
    </>
  );
};

export default function ManageTeam() {
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();
  const [detailsToShow, setDetailsToShow] = useState<Member | null>(null);
  const [memberToDelete, setMemberToDelete] = useState("");
  const [memberToInvite, setMemberToInvite] = useState("");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<SortType>();
  const { loading, items, hasNextPage, error, deleteItem, updateItem } =
    useLoadItems({
      loadItems: team.getAllTeamMembersByStatus,
      page,
      search,
      sortBy,
    });

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: () => setPage(page + 1),
    disabled: !!error,
    rootMargin: "0px 0px 400px 0px",
  });

  const doSearch = (keyword: string) => {
    if (keyword !== search) {
      setPage(1);
      setSearch(keyword);
    }
  };

  const doSort = (sort: SortType) => {
    setPage(1);
    setSortBy(sort);
  };

  const deleteMember = (isOk: boolean) => {
    if (isOk) {
      team
        .deleteMember(memberToDelete)
        .then((res) => {
          deleteItem(memberToDelete);
          setMemberToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setMemberToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  const inviteMember = (isOk: boolean) => {
    if (isOk) {
      team
        .inviteMember(memberToInvite)
        .then((res) => {
          setMemberToInvite("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setMemberToInvite("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  const updateListItem = (updatedData: Member) => {
    updatedData._id = detailsToShow?._id as string;
    updateItem(updatedData);
    setDetailsToShow(null);
  };

  return (
    <>
      <Row>
        <section className="main-container shrink-width" ref={rootRef}>
          <div className="heading-common">
            <h1>
              {t("manageTeamheading")} <span> {t("Team")}</span>!
            </h1>
            <p>{t("mngTeamHeading")}</p>
            <Link to={ROUTE_NAVIGATION_PATH.CREATE_TEAM}>
              <div className="add-btn absolute">{t("addATeamMember")}</div>
            </Link>
          </div>

          {/* {items.length && loading ? */}
          <div className="task-manage-team-wrapper">
            <SearchBar
              doSearch={doSearch}
              placeHolder={t("Search by name, email or postition")}
            />

            <div className="table-scrollbar scrollbar">
              <div className="table-content">
                {!items.length && !loading ? <NoData /> : null}
                <table className="manageteamtable">
                  {items.length ? (
                    <thead>
                      <tr>
                        <th style={{ width: "12%" }}>
                          <TableHeading
                            text={t("Name")}
                            keyName="name"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>
                          <TableHeading
                            text={t("Surname")}
                            keyName="surname"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>{t("Email")}</th>
                        <th>
                          <TableHeading
                            text={
                              <>
                                {t("Filling the")} <br /> questionnaire
                              </>
                            }
                            keyName="isQuestionnaireFilled"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>
                          <TableHeading
                            text={t("Status")}
                            keyName="status"
                            updateSortValue={doSort}
                            sortBy={sortBy}
                          />
                        </th>
                        <th>{t("Activate")}</th>
                        <th>{t("Actions")}</th>
                      </tr>
                    </thead>
                  ) : null}

                  {items.map((member) => (
                    <ManageTeamTile
                      key={member._id}
                      user={member}
                      inviteMember={() => setMemberToInvite(member._id)}
                      editMember={() => setDetailsToShow(member)}
                      deleteMember={() => setMemberToDelete(member._id)}
                      updateListItem={updateItem}
                    />
                  ))}
                  {/* <div className="bottom-graidient" ></div> */}
                  {hasNextPage && (
                    <div ref={sentryRef} className="spinner-wrap">
                      <div className="">
                        <Spinner />
                      </div>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
          {/* : <NoData /> } */}
        </section>
      </Row>
      <EditTeamMember
        detail={detailsToShow}
        handleClose={() => setDetailsToShow(null)}
        updateListItem={updateListItem}
      />
      <ConfirmationModal
        heading={t("removeTeamMeberHeading")}
        body={<p>{t("removeTeamMemberDescription")}</p>}
        show={!!memberToDelete}
        handleClose={() => setMemberToDelete("")}
        type="warning"
        callback={deleteMember}
        buttonMain="Remove"
        buttonSecondary="Cancel"
      />

      <ConfirmationModal
        heading={t("sendInviteQues")}
        body={<p>{t("sendInviteDes")}</p>}
        show={!!memberToInvite}
        handleClose={() => setMemberToInvite("")}
        type="success"
        callback={inviteMember}
        buttonMain="Send an invitation"
        buttonSecondary="Cancel"
      />
    </>
  );
}

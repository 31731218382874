import { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import notification from "../../../assets/images/notification.svg";
import profileIcon from "../../../assets/images/iconprofile.png";
import badges from "../../../assets/images/Badge.svg";
import { useAuth } from "../../../hooks/useAuth";
import {
  AllTeamTaskTile,
  ConfirmationModal,
  EditTask,
  NList,
  TeamSkills,
} from "../../../components";
import { Link } from "react-router-dom";
import { ROUTE_NAVIGATION_PATH } from "../../../routes/routes";
import { team } from "../../../api";
import { useEffect } from "react";
import { Member } from "../../../api/models/User";
import { TASK_CAPACITY, TASK_TYPE } from "../../../constants";
import { task } from "../../../api";
import Spinner from "react-bootstrap/Spinner";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useMessageModal } from "../../../hooks/useMessage";
import { Task } from "../../../api/models/Task";
import TaskCapInfo from "../../../components/Tasks/TaskCapInfo";

export function TaskAllocation() {
  const { t } = useTranslation();
  const [tasks, setTask] = useState<Task[]>([]);
  const [loading, setLoading] = useState(false);
  const [detailsToShow, setDetailsToShow] = useState<any>(null);
  const [taskToDelete, setTaskToDelete] = useState("");
  const { showMessage } = useMessageModal();

  useEffect(() => {
    setLoading(true);
    task
      .teamTask({
        page: 1,
        count: 6,
      })
      .then((res: { data: any }) => {
        setLoading(false);
        setTask(res.data);
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
      });
  }, []);

  const updateListItem = (updatedData: Task) => {
    const index = tasks.findIndex((task) => task._id === detailsToShow?._id);
    setTask((prev) => {
      prev[index] = { ...prev[index], ...updatedData, _id: detailsToShow?._id };
      return prev;
    });
    setDetailsToShow(null);
  };

  const deleteTask = (isOk: boolean) => {
    if (isOk) {
      task
        .deleteTask(taskToDelete)
        .then((res) => {
          const index = tasks.findIndex((task) => task._id === taskToDelete);
          tasks.splice(index, 1);
          setTask(tasks);
          setTaskToDelete("");
        })
        .catch((err: AxiosError<{ errorMessage: string; status: number }>) => {
          setTaskToDelete("");
          showMessage({
            heading: t("Error"),
            body: <p>{err.response?.data?.errorMessage}</p>,
            type: "error",
          });
        });
    }
  };

  return (
    <div className="team-allocation-wrapper">
      <div className="heading">
        <h3>{t("taskAllocation")}</h3>
        {tasks.length !== 0 && !loading ? (
          <Link to={ROUTE_NAVIGATION_PATH.ALL_TASK}>
            <Button variant="seeAll">{t("seeall")}</Button>
          </Link>
        ) : null}
      </div>
      {loading ? (
        <div className="spinner-wrap">
          <div className="">
            <Spinner />
          </div>
        </div>
      ) : (
        <>
          <div className="allocation-content-wrapper">
            {tasks.length === 0 && !loading ? (
              <div className="dashboard-no-data">
                <p>{t("NoResultFound")}</p>
              </div>
            ) : (
              tasks.map((data: any) => (
                <AllTeamTaskTile
                  taskDetails={data}
                  key={data._id}
                  editTask={() => setDetailsToShow(data)}
                  deleteTask={() => setTaskToDelete(data._id)}
                  className="allocation-list-grid"
                />
              ))
            )}
          </div>
          <EditTask
            detail={detailsToShow}
            handleClose={() => setDetailsToShow(null)}
            updateListItem={updateListItem}
          />
          <ConfirmationModal
            heading={t("deleteTaskHeading")}
            body={<p>{t("deleteTaskDes")}</p>}
            show={!!taskToDelete}
            handleClose={() => setTaskToDelete("")}
            type="warning"
            callback={deleteTask}
            buttonMain="Remove"
            buttonSecondary="Cancel"
          />
        </>
      )}
    </div>
  );
}

export function TeamMember() {
  const { user } = useAuth();
  const [members, setMembers] = useState<Member[]>([]);
  const [count, setCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    team
      .getTeamMembers({
        page: 1,
        count: 2,
      })
      .then((res: { data: Member[], totalData?: number }) => {
        console.log(res);
        setMembers(res.data);
        setLoading(false);
        setCount(res.totalData as number)
      })
      .catch((error: AxiosError<{ errorMessage: string; status: number }>) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <h2>{t("aboutyourteam")}</h2>
      <div className="team-member-wrapper">
        <div className="heading">
          <h3>
            {t("teamMembers")} ({count})
          </h3>
          {count === 0 ? null : (
            <Link to={ROUTE_NAVIGATION_PATH.MEMBER_LIST}>
              <Button variant="seeAll">{t("seeall")}</Button>
            </Link>
          )}
        </div>
        {loading ? (
          <div className="spinner-wrap">
            <div className="">
              <Spinner />
            </div>
          </div>
        ) : (
          <div className="team-member-content-wrapper">
            {members.length === 0 && !loading ? (
              <div className="dashboard-no-data">
                <p>{t("NoResultFound")}</p>
              </div>
            ) : null}
            {members.map((member) => (
              <div className="team-member-list-grid" key={member._id}>
                <div className="_profile-img">
                  <img src={badges} alt="Badges" className="badges" />
                  <img
                    src={member?.image ? member?.image : profileIcon}
                    onError={(e: any) => {
                      e.target.src = profileIcon;
                    }}
                    alt="ProfileImg"
                  />
                </div>
                <div className="name">
                  {member.name} {member.surname}
                </div>
                {member?.position ? (
                  <div className="designation">{member.position}</div>
                ) : null}

                <div className="free-slot">
                  <div className="text"> {t("Capacity")}:</div>
                  <div className="task-capacity-n smll-grid">
                    <div className="tsk-capacity-c-bt _small-n">
                      S{" "}
                      {Object.keys(member.teamMemberTaskCount).length === 0
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.S] === undefined
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.S]}
                      /{TASK_CAPACITY.SMALL}
                    </div>
                    <div className="tsk-capacity-c-bt _medium-n">
                      M{" "}
                      {Object.keys(member.teamMemberTaskCount).length === 0
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.M] === undefined
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.M]}
                      /{TASK_CAPACITY.MEDIUM}
                    </div>
                    <div className="tsk-capacity-c-bt _large-n">
                      L{" "}
                      {Object.keys(member.teamMemberTaskCount).length === 0
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.L] === undefined
                        ? 0
                        : member.teamMemberTaskCount[TASK_TYPE.L]}
                      /{TASK_CAPACITY.LARGE}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default function LeaderDashboard() {
  const { user } = useAuth();
  const [expendMenu, setExpendMenu] = useState(false);
  const [taskCount, settaskCount] = useState<any>();
  const { t } = useTranslation();
  const { showMessage } = useMessageModal();


  const capitalizeFirst = (str: any) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
  };

  function toggleMenuExpend() {
    setExpendMenu(!expendMenu);
  }

  useEffect(() => {
    if (window.screen.width < 768) {
      showMessage({
        heading: "Error",
        body: <p>{t("Mobile Screen Info")}</p>,
        type: "error",
        callback: () => {
          localStorage.clear();
          window.location.href = '/';
        },
      });
    }
    task
      .getLeadertaskCount()
      .then((res: { data: any }) => {
        settaskCount(res.data);
      })
      .catch(
        (error: AxiosError<{ errorMessage: string; status: number }>) => {}
      );
  }, []);

  return (
    <>
      <Row>
        <section className="notification-bar shrink-width">
          <div>
            {t("goodMorning")}, {capitalizeFirst(user?.name)}! 👋
          </div>
          <div className="notification" onClick={toggleMenuExpend}>
            <img src={notification} alt="notification" />
            {user?.totalNotificationsCount &&
            user?.totalNotificationsCount > 0 ? (
              <span className="m-alert"></span>
            ) : null}
          </div>
          {expendMenu ? <NList /> : null}
        </section>
        <section className="main-container transparent-bg shrink-width">
          <div className="task-information-wrapper">
            <Col md={12} xl={8} className="information-left-panel">
              <h2>
                {t("tasksinformation")}{" "}
                <TaskCapInfo />
              </h2>
              <div className="task-info-content">
                <div className="total-task">
                  {taskCount?.totalCount}
                  <p>{t("totalTask")}</p>
                </div>
                <div className="sub-task-info">
                  <div className="grid">
                    <div className="count-with-progress">
                      <div className="barcontainer">
                        <div className="progress vertical">
                          <div
                            className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={taskCount?.notAssigned}
                            aria-valuemin={100}
                            aria-valuemax={taskCount?.totalCount}
                            style={{
                              height: taskCount?.notAssigned * 10 + "%",
                              background: "#B1B7C0",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span>{taskCount?.notAssigned}</span>
                    </div>
                    <p>{t("plan")}</p>
                  </div>
                  <div className="grid">
                    <div className="count-with-progress">
                      <div className="barcontainer">
                        <div className="progress vertical">
                          <div
                            className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={taskCount?.suggestions}
                            aria-valuemin={0}
                            aria-valuemax={taskCount?.totalCount}
                            style={{
                              height: taskCount?.suggestions * 10 + "%",
                              background: "#40BBBD",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span>{taskCount?.suggestions}</span>
                    </div>
                    <p>{t("suggestion")}</p>
                  </div>
                  <div className="grid">
                    <div className="count-with-progress">
                      <div className="barcontainer">
                        {/* <ProgressBar now={now} label={`${now}%`} /> */}
                        <div className="progress vertical">
                          <div
                            className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={taskCount?.pending}
                            aria-valuemin={40}
                            aria-valuemax={taskCount?.totalCount}
                            style={{
                              height: taskCount?.pending * 10 + "%",
                              background: "#efbf62",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span>{taskCount?.pending}</span>
                    </div>
                    <p>{t("pending")}</p>
                  </div>
                  <div className="grid">
                    <div className="count-with-progress">
                      <div className="barcontainer">
                        <div className="progress vertical">
                          <div
                            className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={taskCount?.assigned}
                            aria-valuemin={100}
                            aria-valuemax={taskCount?.totalCount}
                            style={{
                              height: taskCount?.assigned * 10 + "%",
                              background: "#EFBF62",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span>{taskCount?.assigned}</span>
                    </div>
                    <p>{t("assigned")}</p>
                  </div>
                  <div className="grid">
                    <div className="count-with-progress">
                      <div className="barcontainer">
                        <div className="progress vertical">
                          <div
                            className="progress-bar progress-bar-success"
                            role="progressbar"
                            aria-valuenow={taskCount?.done}
                            aria-valuemin={100}
                            aria-valuemax={taskCount?.totalCount}
                            style={{
                              height: taskCount?.done * 10 + "%",
                              background: "#71C49C",
                            }}
                          ></div>
                        </div>
                      </div>
                      <span>{taskCount?.done}</span>
                    </div>
                    <p>{t("doneTasks")}</p>
                  </div>
                </div>
              </div>

              <TaskAllocation />
            </Col>
            <Col md={12} xl={4} className="information-right-panel">
              <TeamMember />
              <TeamSkills />
            </Col>
          </div>
        </section>
      </Row>
    </>
  );
}
